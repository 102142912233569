<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col cols="12">
          <div class="form-group">
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </div>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onShowAddForm"
          >
            New Sales Order
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active === 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(status)="data">
          <div class="text-nowrap">
            {{ getStatus(data.item) }}
          </div>
        </template>

        <template #cell(warehouse_pick_up)="data">
          <span :class="data.item.warehouse_pick_up == 1 ? 'text-success' :'text-danger' ">
            {{ data.item.warehouse_pick_up === 1 ? 'Yes' : 'No' }}
          </span>
        </template>

        <template #cell(is_close)="data">
          <span :class="data.item.is_close == 1 ? 'text-success' :'text-danger' ">
            {{ data.item.is_close === 1 ? 'Yes' : 'No' }}
          </span>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="showSODetailsForm(row.item)"
            >
              Show Details
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-salesorder-form"
      scrollable
      no-close-on-backdrop
      title="New Sales Order"
      size="xl"
      keyboard="false"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <b-tabs
            v-model="tabIndex"
            no-body
          >
            <b-tab
              title="Customer Details"
              active
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="distribution"
                    vid="distribution"
                    rules="required"
                  >
                    <div class="form-group">
                      <label for="distribution">Distribution</label>
                      <v-select
                        id="distribution"
                        v-model="salesOrderForm.distribution"
                        :options="distributions"
                        label="distribution_name"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="customer name"
                    vid="customer_name"
                    rules="required"
                  >
                    <div class="form-group">
                      <label for="customer_name">Customer Name</label>
                      <v-select
                        id="customer_name"
                        v-model="salesOrderForm.customer"
                        :options="customers"
                        label="customer_name"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <div class="form-group">
                    <label for="customer_code">Customer Code</label>
                    <b-input
                      id="customer_code"
                      v-model="salesOrderForm.customer_code"
                      type="text"
                      :disabled="true"
                    />
                  </div>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="payment term"
                    vid="term"
                    rules="required"
                  >
                    <div class="form-group">
                      <label for="term">Payment Term</label>
                      <b-form-select
                        id="term"
                        v-model="salesOrderForm.term_id"
                        :options="terms"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Payment Term --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="discount"
                    vid="discount"
                    rules="required"
                  >
                    <div class="form-group">
                      <label for="discount">Discount</label>
                      <b-form-select
                        id="discount"
                        v-model="salesOrderForm.discount_id"
                        :options="discounts"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Discount --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="discount value"
                    vid="discount_value"
                    rules="required"
                  >
                    <div class="form-group">
                      <label for="discount_value">Discount Value</label>
                      <b-input
                        id="discount_value"
                        v-model="salesOrderForm.discount_value"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter discount value"
                        autocomplete="off"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab title="Item Details">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <div class="form-group">
                    <label for="product_category">Product Category</label>
                    <v-select
                      id="product_category"
                      v-model="selectedProductCategory"
                      :options="productCategories"
                      label="category_name"
                    />
                  </div>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <div class="form-group">
                    <label for="product">Product</label>
                    <v-select
                      id="product"
                      v-model="selectedProduct"
                      :options="products"
                      label="product_name"
                    />
                  </div>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <div class="form-group">
                    <label for="qty">Quantity</label>
                    <b-input
                      id="qty"
                      v-model.number="orderItemForm.qty"
                      type="number"
                      placeholder="enter quantity"
                      autocomplete="off"
                    />
                  </div>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <div class="form-group">
                    <label for="price">Price</label>
                    <b-input
                      id="price"
                      v-model="orderItemForm.price"
                      type="number"
                      placeholder="enter price"
                      autocomplete="off"
                    />
                  </div>
                </b-col>

                <b-col
                  cols="12"
                  class="text-left"
                >
                  <b-button
                    type="button"
                    variant="success"
                    :disabled="addItemDisabled"
                    @click="onAddItem"
                  >
                    Add Item
                  </b-button>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col cols="12">
                  <b-table
                    striped
                    hover
                    :items="items"
                    :fields="tableOrderItemSettings"
                  >
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>

                    <template #cell(actions)="row">
                      <b-button
                        size="sm"
                        @click="onRemoveItem(row.item)"
                      >
                        remove
                      </b-button>
                    </template>
                  </b-table>
                </b-col>
                <b-col
                  cols="12"
                  class="d-flex align-items-end justify-content-end"
                >
                  <b>Total Cost:&nbsp;<span>{{ numberFormatter(totalCost) }}</span></b>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              title="Remarks"
            >
              <b-row>
                <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="remarks"
                    vid="remarks"
                    rules="required|max:500"
                  >
                    <div class="form-group">
                      <label for="remarks">Remarks</label>
                      <b-textarea
                        id="remarks"
                        v-model="salesOrderForm.remarks"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter remarks"
                        autocomplete="off"
                        rows="6"
                        max-rows="6"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12">
                  Customer Signature
                  <VPerfectSignature
                    v-if="tabIndex === 2"
                    id="signature"
                    ref="signaturePad"
                    class="signature"
                  />
                </b-col>

                <b-col cols="12">
                  <b-button
                    variant="danger"
                    @click="onClearSignature"
                  >
                    Clear Signature
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>

          </b-tabs>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="isBusy || items.length < 1"
          @click="ok()"
        >
          Submit
        </b-button>
        <b-button
          variant="danger"
          :disabled="isBusy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!--modal-->
    <b-modal
      id="modal-show-details"
      scrollable
      no-close-on-backdrop
      title="Show Details"
      size="xl"
    >
      <b-tabs
        no-body
      >
        <b-tab
          title="Order Details"
          active
        >
          <b-row>
            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group>
                <label for="created_at">Transaction Date</label>
                <b-input
                  id="created_at"
                  v-model="salesOrderDetails.created_at"
                  name="created_at"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group>
                <label for="document_number">Document Number</label>
                <b-input
                  id="document_number"
                  v-model="salesOrderDetails.document_number"
                  name="document_number"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group>
                <label for="customer_code">Customer Code</label>
                <b-input
                  id="customer_code"
                  v-model="salesOrderDetails.customer_code"
                  name="customer_code"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label for="customer_name">Customer Name</label>
                <b-input
                  id="customer_name"
                  v-model="salesOrderDetails.customer_name"
                  name="customer_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="3"
            >
              <b-form-group>
                <label for="term_name">Payment Term</label>
                <b-input
                  id="term_name"
                  v-model="salesOrderDetails.term_name"
                  name="term_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="3"
            >
              <b-form-group>
                <label for="discount_name">Discount Type</label>
                <b-input
                  id="discount_name"
                  v-model="salesOrderDetails.discount_name"
                  name="discount_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="3"
            >
              <b-form-group>
                <label for="discount_percentage">Discount %</label>
                <b-input
                  id="discount_percentage"
                  v-model="salesOrderDetails.discount_percentage"
                  name="discount_percentage"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="3"
            >
              <b-form-group>
                <label for="shipment_type">Shipment Type</label>
                <b-input
                  id="shipment_type"
                  :value="salesOrderDetails.warehouse_pick_up === 1 ? 'For Pick Up' : 'For Delivery'"
                  name="shipment_type"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label for="remarks">Remarks</label>
                <b-textarea
                  id="remarks"
                  v-model="salesOrderDetails.remarks"
                  name="remarks"
                  type="text"
                  :disabled="true"
                  rows="6"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              class="mb-2"
            >
              <strong>Ordered Item(s)</strong>
            </b-col>

            <b-col cols="12">
              <b-table
                striped
                hover
                :items="list_items"
                :fields="tableOrderDetailSettings.fields"
                class="text-center"
              >
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>
              </b-table>
            </b-col>
            <b-col
              class="col-md-12 d-flex align-items-end justify-content-end"
            >
              <b>Total Cost:&nbsp;<span>{{ numberFormatter(salesOrderDetails.total_cost) }}</span></b>
            </b-col>
          </b-row>

        </b-tab>

        <b-tab
          v-if="product_history.data.length > 0"
          title="Transaction History"
        >
          <b-card
            v-for="(product, index) in product_history.data"
            :key="index"
            no-body
          >
            <p
              v-b-toggle="`collapse-${index}-inner`"
              class="pl-2 py-2 m-0"
              :style="`cursor:pointer;  ${product.status === 'Delivered' || product.status === 'Picked-up' ? 'background-color:#06C270;color:white' : 'background-color:#dee2e6'}`"
            >
              Transaction Date: {{ dateFormatter(product.delivered_at) }}
            </p>
            <b-collapse :id="`collapse-${index}-inner`">
              <p class="mt-2">
                Prepared By: {{ product.approved_by }}<br>
                Status: {{ product.status }}
              </p>
              <b-table
                striped
                hover
                :items="product.transaction_items"
                :fields="product_history.fields"
              >
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(product_name)="data">
                  {{ data.item.sale_order_detail.product_name }}
                </template>
              </b-table>
            </b-collapse>
          </b-card>
        </b-tab>

      </b-tabs>

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          :disabled="isBusy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SharedListService, SharedSalesOrderService, AxiosError } from '@/services'
import VPerfectSignature from 'v-perfect-signature'
import formatter from '@/mixins/formatter'

export default {
  name: 'UserSalesOrder',

  middleware: ['auth', 'user'],

  components: {
    VPerfectSignature
  },

  mixins: [formatter],

  metaInfo () {
    return {
      title: 'Sales Order'
    }
  },

  data () {
    return {
      isBusy: false,
      tabIndex: 0,
      selectedProduct: null,
      selectedProductCategory: null,
      distributions: [],
      customers: [],
      terms: [],
      discounts: [],
      productCategories: [],
      products: [],
      items: [],
      list_items: [],
      totalCost: 0,
      strokeOptions: {
        size: 16,
        thinning: 0.75,
        smoothing: 0.5,
        streamline: 0.5
      },
      salesOrderForm: {
        distribution: null,
        document_number: '',
        customer: null,
        customer_code: '',
        term_id: null,
        discount: null,
        percent: 0,
        remarks: ''
      },
      orderItemForm: {
        qty: 0,
        price: 0
      },
      salesOrderDetails: {
        id: 0,
        created_at: '',
        document_number: '',
        customer_code: '',
        customer_name: '',
        term_name: '',
        discount_name: '',
        discount_percentage: 0,
        remarks: '',
        warehouse_pick_up: 0,
        total_cost: 0
      },
      product_history: {
        fields: [
          'index',
          { key: 'product_name' },
          { key: 'quantity' }
        ],
        data: []
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', sortable: true, formatter: this.dateTimeFormatter },
          { key: 'document_number.', sortable: true },
          { key: 'customer_name', sortable: true },
          { key: 'status', sortable: true },
          { key: 'group_approver', sortable: true },
          { key: 'warehouse_pick_up', label: 'Warehouse Pick Up?', sortable: true },
          { key: 'is_close', label: 'Is Close ?', sortable: true }
          // { key: 'distribution_code', sortable: true }
        ]
      },
      tableOrderItemSettings: [
        'index',
        'actions',
        { key: 'category_name' },
        { key: 'product_name' },
        { key: 'quantity' },
        { key: 'price', formatter: this.numberFormatter, tdClass: 'text-right' },
        { key: 'total_price', formatter: this.numberFormatter, tdClass: 'text-right' }
      ],
      tableOrderDetailSettings: {
        fields: [
          'index',
          { key: 'category_name' },
          { key: 'product_name' },
          { key: 'quantity' },
          { key: 'delivered_quantity' },
          { key: 'pending_deliver' },
          { key: 'price', formatter: this.numberFormatter, tdClass: 'text-right' },
          { key: 'total_price', formatter: this.numberFormatter, tdClass: 'text-right' }
        ]
      }
    }
  },

  computed: {
    addItemDisabled () {
      return !(this.selectedProduct !== null && this.selectedProductCategory !== null && parseInt(this.orderItemForm.qty) > 0 && parseFloat(this.orderItemForm.price) > 0)
    }
  },

  watch: {
    'salesOrderForm.distribution' (value) {
      if (value) {
        this.fetchCustomerList(value.id)
      }
    },

    'salesOrderForm.customer' (value) {
      if (value) {
        this.salesOrderForm.customer_code = value.customer_code
      } else {
        this.salesOrderForm.customer_code = ''
      }
    },

    'selectedProductCategory' (value) {
      if (value) {
        this.fetchProductList(value.id)
      }
    },

    'items' (value) {
      this.totalCost = value?.reduce((a, b) => {
        const totalAmount = a + b.quantity * b.price
        return totalAmount
      }, 0)
    }
  },

  mounted () {
    core.index()
    this.fetchDistributionList()
    this.fetchTermList()
    this.fetchDiscountList()
    this.fetchProductCategoryList()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await SharedSalesOrderService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}`)
        this.tableSettings.totalRows = data.total_rows
        return data.data
      } catch {
        return []
      }
    },

    async fetchDistributionList () {
      this.distributions = []
      await SharedListService.getDistributionList().then(
        ({ data }) => {
          this.distributions = data
        }
      )
    },

    async fetchCustomerList (distributions) {
      this.customers = []
      await SharedListService.getCustomerList(distributions).then(
        ({ data }) => {
          this.customers = data
        }
      )
    },

    async fetchTermList () {
      this.terms = []
      await SharedListService.getTermList().then(({ data }) => {
        data.forEach(item => {
          this.terms.push({
            value: item.id,
            text: item.term_name
          })
        })
      })
    },

    async fetchDiscountList () {
      this.discounts = []
      await SharedListService.getDiscountList().then(({ data }) => {
        data.forEach(item => {
          this.discounts.push({
            value: item.id,
            text: item.discount_name
          })
        })
      })
    },

    async fetchProductCategoryList () {
      this.productCategories = []
      await SharedListService.getProductCategoryList().then(({ data }) => {
        this.productCategories = data
      })
    },

    async fetchProductList (category) {
      this.products = []
      await SharedListService.getProductList(category).then(({ data }) => {
        this.products = data
      })
    },

    onClearSignature (event) {
      event.preventDefault()
      this.$refs.signaturePad.clear()
    },

    getStatus (row) {
      let newStatus = row.status

      if (row.group_approver === 'Warehouse') {
        if (row.status === 'Approved') {
          newStatus = 'Fully Serve'
        } else if (row.status === 'Partially Approve') {
          newStatus = 'Partially Serve'
        } else {
          newStatus = row.status
        }
      } else if (row.group_approver === 'Logistics') {
        if (row.status === 'Pending') {
          newStatus = 'For Delivery'
        } else if (row.status === 'Delivered') {
          newStatus = 'Fully Delivered'
        } else {
          newStatus = row.status
        }
      }

      return newStatus
    },

    toDataURL () {
      return this.$refs.signaturePad?.toDataURL() || null
    },

    onShowAddForm () {
      this.onResetForm()
      this.$bvModal.show('modal-salesorder-form')
    },

    onResetForm () {
      this.salesOrderForm.distribution = null
      this.salesOrderForm.document_number = ''
      this.salesOrderForm.customer = null
      this.salesOrderForm.term_id = null
      this.salesOrderForm.discount_id = null
      this.salesOrderForm.discount_value = 0
      this.salesOrderForm.remarks = ''
      this.orderItemForm.qty = 1
      this.orderItemForm.price = 0
      this.selectedProductCategory = null
      this.selectedProduct = null
      this.items = []
    },

    onAddItem () {
      const isExisting = this.items?.filter(
        el => el.product_code === this.selectedProduct.product_code
      )
      const x = -0
      if (this.orderItemForm.qty <= x || this.orderItemForm.price < x) {
        alert(
          'please check quantity or price inputs not accepting negative value'
        )
      } else {
        if (isExisting.length) {
          const newData = this.items.map(el => {
            if (
              el.product_code === this.selectedProduct.product_code
            ) {
              el.quantity = Number(el.quantity) + Number(this.orderItemForm.qty)
              el.price = this.orderItemForm.price
              el.total_price = Number(el.quantity) * Number(this.orderItemForm.price)
              return el
            }

            return el
          })

          this.items = newData
        } else {
          this.items.push({
            category_code: this.selectedProductCategory.category_code,
            category_name: this.selectedProductCategory.category_name,
            product_code: this.selectedProduct.product_code,
            product_name: this.selectedProduct.product_name,
            quantity: this.orderItemForm.qty,
            price: this.orderItemForm.price,
            total_price: Number(this.orderItemForm.qty) * Number(this.orderItemForm.price)
          })
        }
      }

      // reset quantity & price
      this.orderItemForm.qty = 1
      this.orderItemForm.price = 0
    },

    showSODetailsForm (item) {
      this.salesOrderDetails.id = item.id
      this.salesOrderDetails.created_at = this.dateTimeFormatter(item.created_at)
      this.salesOrderDetails.document_number = item.document_number
      this.salesOrderDetails.customer_code = item.customer_code
      this.salesOrderDetails.customer_name = item.customer_name
      this.salesOrderDetails.term_name = item.term_name
      this.salesOrderDetails.discount_name = item.discount_name
      this.salesOrderDetails.discount_percentage = item.discount_percentage
      this.salesOrderDetails.remarks = item.remarks
      this.salesOrderDetails.warehouse_pick_up = item.warehouse_pick_up

      const data = item.sale_order_details.map(x => {
        return {
          id: x.id,
          category_name: x.category_name,
          product_name: x.product_name,
          quantity: x.quantity,
          price: x.price,
          total_price: parseInt(x.quantity) * parseFloat(x.price),
          quantity_out: 0,
          delivered_quantity: this.getQuantity(x.id, item, ['Delivered', 'Picked-up']),
          disabled: x.quantity_out === x.quantity,
          pending_deliver: this.getQuantity(x.id, item, ['Pending'])
        }
      })

      this.list_items = data
      this.product_history.data = item?.transactions || []
      const sumTotalPrice = data.reduce((acc, ele) => {
        return acc + parseFloat(ele.total_price)
      }, 0)

      this.salesOrderDetails.total_cost = sumTotalPrice
      this.$bvModal.show('modal-show-details')
    },

    getQuantity (id, item, status) {
      return item?.transactions.filter(el => {
        if (status.indexOf(el.status) !== -1) {
          return el
        }
      }).flatMap(el => {
        return el?.transaction_items.map((t, ti) => {
          if (id === t.sale_order_detail_id) {
            return t
          } else {
            return { quantity: 0 }
          }
        })
      })?.reduce((prev, current) => {
        // eslint-disable-next-line no-return-assign
        return prev += current.quantity
      }, 0)
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()

      await this.$refs.form.validate().then(async success => {
        if (success) {
          if (this.toDataURL()) {
            this.$swal.fire({
              icon: 'question',
              title: 'Are you sure want to submit sales order?',
              showCancelButton: true,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Confirm',
              cancelButtonColor: '#FF2929',
              showLoaderOnConfirm: true,
              preConfirm: async () => {
                return this.onSubmitSalesOrder()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          } else {
            this.$bvModal.msgBoxOk('Error in saving data, signature is required.', {
              title: 'Validation Error',
              size: 'md',
              buttonSize: 'sm',
              okVariant: 'danger'
            })
          }
        } else {
          this.$bvModal.msgBoxOk('Error in saving data. make sure that all fields are filled.', {
            title: 'Validation Error',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger'
          })
        }
      })
    },

    async onSubmitSalesOrder () {
      return new Promise((resolve, reject) => {
        this.isBusy = true

        const params = {
          distribution_id: this.salesOrderForm.distribution?.id,
          document_number: this.salesOrderForm.document_number,
          customer_code: this.salesOrderForm.customer?.customer_code,
          term_id: this.salesOrderForm.term_id,
          discount_id: this.salesOrderForm.discount_id,
          discount_percentage: this.salesOrderForm.discount_value,
          remarks: this.salesOrderForm.remarks,
          sale_order_details: this.items,
          signature: this.toDataURL(),
          is_edit: 0
        }

        SharedSalesOrderService.post(params).then(response => {
          this.isBusy = false
          this.$bvModal.hide('modal-salesorder-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: response.data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          })
            .then(() => {
              this.onResetForm()
              this.$refs.table.refresh()
            })
        }).catch(error => {
          this.isBusy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onRemoveItem (item) {
      const oldItem = this.items

      const newItem = oldItem.filter(el => {
        return el.product_code !== item.product_code
      })

      this.items = newItem
    }
  }
}
</script>

<style scoped>
  .signature {
    border: double 3px transparent;
    border-radius: 5px;
    background-image: linear-gradient(white, white),
      radial-gradient(circle at top left, #4bc5e8, #9f6274);
    background-origin: border-box;
    background-clip: content-box, border-box;
    width: 100% !important;
    height: 200px !important;
  }
  canvas {
    aspect-ratio: auto 572 / 250;
  }
</style>
